export const ADD_SUCCESS = {
  BOARD_UNIVERSITY: 'Board / University added successfully',
  STREAM: 'Stream added successfully',
  COURSE: 'Course added successfully',
  CLASS: 'Class  added successfully',
  SUBJECT: 'Subject added successfully',
  SUBJECT_GROUP: 'Subject Group added successfully',
  BATCH: 'Batch added successfully',
  YEARS: 'Years added successfully',
  INSTITUTE: 'Institute added successfully',
  INVITE_USER: 'User invited successfully',
  NEWS: 'News added successfully',
  NOTICE: 'Notice added successfully',
  INVITE_STUDENT: 'Student invited successfully',
  ASSIGN_SUBJECT: 'Subject assigned to employee successfully',
  ASSIGNMENT: 'Assignment created successfully',
  RESET_PASSWORD_EMAIL: 'Password Reset Email has been sent',
  LANGUAGE: 'Language added successfully',
  SCHEDULE: 'Lecture scheduled successfully',
  UPDATE_INSTITUTE_SETTINGS: 'Updated Institute settings successfully',
  EVENT: 'Event added successfully',
  PAYMENT_SUCCESSFULL: 'Payment Successfull',
  MODULE: 'Module added successfully',
  FEE_COMPOSITION: 'Fee composition added successfully',
  BANK_ACCOUNT_SUCCESSFULL: 'Bank Account Added Successfull',
  LINK_ACCOUNT: 'Account Linked Successfully',
  CONCESSION_SUCCESS: 'Concession Created Successfully',
  SEND_REMINDER: 'Reminder sent successfully',
  STUDENT_GROUP: 'Student group added successfully',
  MESSAGE_SENT: 'Message sent successfully',
  GRADE: 'Grade added successfully',
  TEST: 'Test added successfully',
  MARKS: 'Marks added successfully',
  EXAM: 'Exam added successfully',
  ADMISSION: 'Admission added successfully',
  REGISTRATION_SUCCESSFUL: 'Congratulations on successfully registering! Kindly check your email for the next steps to set up your account.',
  CRITERIA: 'Criteria added successfully',
  DOCUMENT: 'Document added successfully',
  CASTE_CATEGORY: 'Caste Category added successfully',
  RESERVATION_GROUP: 'Reservation group added successfully',
  QUALIFICATION: 'Qualification added successfully',
  ASSIGN_ROLL_NO: 'Roll number assigned successfully',
  CHANGE_DIVISION: 'Divisions changed successfully',
  ASSIGN_DIVISION: 'Division assigned successfully',
  ADMISSION_ROUND: 'Round added successfully',
  ADMISSION_LINK: 'Link added successfully',
  MERCHANT_DETAILS: "Merchant Details added successfully",
  INVITE_CAP_APPLICANT: 'Invited Cap Applicant Successfully',
  INVITE_DIRECT_APPLICANT: 'Invited Direct Applicant Successfully',
  PARTIAL_PAYMENT: 'Partial Payment added successfully',
  REPORT_GENERATION: 'Request received. Report generation in progress. Please wait.',
  CERTIFICATE_GENERATION: 'Request received. Certificate generation in progress. Please wait.',
  ID_CARD_GENERATION: 'Request received. ID Card generation in progress, Please wait.',
  ADMISSION_CANCELLATION_REPORT: "Request received. Admission Cancellation Report generation in progress, You'll be notified through email or notification on completion.",
  DISABLED_STUDENTS_REPORT: "Request received. Disabled Students Report generation in progress, You'll be notified through email or notification on completion.",
  PRINTABLE_ID_CARD_GENERATION: "Request received. Printable ID Card generation in progress, You'll be notified through email or notification on completion.",
  RECONCILE_PAYMENT: 'Request submitted for payment reconciliation successfully',
  FEE_RECEIPT_GENERATION: 'Request received. Receipt generation in progress, Please wait.',
  OTHER_PAYMENT: 'Other Payment added successfully',
  ID_CARD_REGENERATION: 'Request received. ID Card regeneration in progress, Please wait.',
  FEE_RECEIPT_REGENERATION: 'Request received. Receipt regeneration in progress, Please wait.',
  PRINTABLE_ID_CARD_REGENERATION: "Request received. Printable ID Card regeneration in progress, You'll be notified through email or notification on completion.",
  PUBLISH_RESULTS: 'Results published successfully',
  UNPUBLISH_RESULTS: 'Results unpublished successfully',
  APPLY_GRACE_BY_RESOLUTION: 'Applied grace by resolution successfully',
  APPLY_GRACE_BY_RULESET: 'Applied grace by ruleset successfully',
  REGENERATE_MARKSHEETS: 'Marksheets regenerated successfully',
  RESEND_EMAILS: 'Emails sent successfully',
  RESEND_EMAIL: 'Email sent successfully',
  ASSIGN_REGISTRATION: 'Assigned Registration successfully',
};

export const DELETE_SUCCESS = {
  BOARD_UNIVERSITY: 'Board / University deleted successfully',
  STREAM: 'Stream deleted successfully',
  COURSE: 'Course deleted successfully',
  CLASS: 'Class  deleted successfully',
  SUBJECT: 'Subject deleted successfully',
  SUBJECT_GROUP: 'Subject Group deleted successfully',
  INSTITUTE: 'Institute deleted successfully',
  LANGUAGE: 'Language deleted successfully',
  EVENT: 'Event deleted successfully',
  ASSIGNMENT: 'Assignment deleted successfully',
  NEWS: 'News deleted successfully',
  NOTICE: 'Notice deleted successfully',
  STUDENT: 'Student deleted successfully',
  BATCH: 'Batch deleted successfully',
  FEE_COMPOSITION: 'Fee Composition deleted successfully',
  MODULE: 'Module deleted successfully',
  TEST: 'Test deleted successfully',
  EXAM: 'Exam deleted successfully',
  LINK: 'Link deleted successfully',
} as any;

export const UPDATE_SUCCESS = {
  BOARD_UNIVERSITY: 'Board / University updated successfully',
  STREAM: 'Stream updated successfully',
  COURSE: 'Course updated successfully',
  CLASS: 'Class  updated successfully',
  SUBJECT: 'Subject updated successfully',
  SUBJECT_GROUP: 'Subject Group updated successfully',
  INSTITUTE: 'Institute updated successfully',
  LANGUAGE: 'Language updated successfully',
  LOCALIZATION_KEY_VALUE: 'Localization value updated successfully',
  LANGUAGE_APPLIED: 'Language changed successfully. Re-login to see your changes.',
  EVENT: 'Event updated successfully',
  PROFILE: 'Profile updated successfully',
  EDUCATION: 'Education updated successfully',
  EXPERIENCE: 'Experience updated successfully',
  ASSIGNMENT: 'Assignment updated successfully',
  BATCH: 'Batch updated successfully',
  YEARS: 'Academic year updated successfully',
  PAYMENT_STATUS: 'Payment status updated successfully',
  GRADE: 'Grade updated successfully',
  TEST: 'Test updated successfully',
  EXAM: 'Exam updated successfully',
  MARKS: 'Marks updated successfully',
  INSTITUTE_MODULE: 'Module updated successfully',
  ALL_CHANGES_APPROVED: 'All profile changes approves successfully',
  CHANGES_APPROVED: 'Profile changes approved successfully',
  CHANGES_REJECTED: 'Profile changes rejected successfully',
  CHANGES_SUBMITTED: 'Profile changes submitted for review successfully',
  ADMISSION: 'Admission updated successfully',
  CRITERIA: 'Criteria updated successfully',
  DOCUMENT: 'Document updated successfully',
  CASTE_CATEGORY: 'Caste Category updated successfully',
  RSERVATION_GROUP: 'Reservation group updated successfully',
  QUALIFICATION: 'Qualification updated successfully',
  CREDITS: 'Updated credits successfully',
  ADMISSION_ROUND: 'Round updated successfully',
  ATTENDANCE: "Attendance updated successfully",
  ADMISSION_LINK: 'Link updated successfully',
  MERCHANT_DETAILS: "Merchant Details updated successfully",
  ASSIGN_ADMIN_ROLE: 'Assigned admin role successfully',
  REVOKE_ADMIN_ROLE: 'Revoked admin role successfully',
  FEE_COMPOSITION: 'Fee composition updated successfully',
  SCROLL_REF: 'Scroll Number successfully',
  TRANSFER_ADMISSION: 'Transferred admission successfully',
  FEE_COLLECTION: 'Changed Fee Collection successfully',
  PAYMENT: 'Payment updated successfully',
  SCHEDULE: 'Lecture updated successfully',
  ASSIGN_MARKS: 'Assigned marks successfully',
  BLOCK_RESULT: 'Result blocked successfully',
  UNBLOCK_RESULT: 'Result unblocked successfully',
  DEACTIVATE_LINK: 'Deactivated link successfully',
  ACTIVATE_LINK: 'Activated link successfully',
  RESULT: 'Results updated successfully',
  MARKSHEET_DETAILS: 'Marksheet details updated successfully',
  PAYER_DETAILS: 'Payers details updated successfully',
};

export const CLONE_SUCCESS = {
  GRADE_CLONE_SUCCESS: 'Grade(s) cloned successfully',
  TEST_CLONE_SUCCESS: 'Test(s) cloned successfully',
}

export const VERIFICATION_SUCCESS = {
  VERIFICATION_SENT_SUCCESS: 'Verification sent successfully',
}

export const REFUND_SUCCESS = {
  REFUND_INITIATED: 'Refund initiated successfully',
  REFUND_COMPLETED: 'Refund completed successfully',
}

export const ENABLE_SUCCESS = {
  ENABLED_ATKT: 'ATKT Form enabled successfully',
  ENABLED_REVALUTION: 'Revaluation Form enabled successfully',
}

export const GRADE_ERROR= {
  NOT_FOUND: 'Grade not found'
}