import {
  faFilePdf,
  faFileImage,
  faFileCsv,
  faFileExcel,
  faFileVideo,
  faDownload,
  faFileAlt,
  faFile,
  faFileWord,
  faFileSpreadsheet,
} from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'src/styles/theme/styles';

export const limit = 50;
export const autoCompleteLimit = 50;
export const SEARCH_TIME = 500;
export const FILTERS = {
  EQ: 'eq',
  REGEX: 'regex',
  OR: 'or',
};

export const instituteOptions = {
  SCHOOL: {
    label: 'School',
    value: 'SCHOOL',
  },
  COLLEGE: {
    label: 'College',
    value: 'COLLEGE',
  },
  GROUP: {
    label: 'Group of Institute',
    value: 'GROUP',
  },
};
export const NO_GROUP_OPTIONS = [
  {
    label: 'School',
    value: 'SCHOOL',
  },
  {
    label: 'College',
    value: 'COLLEGE',
  },
];
export const INSTITUTE_ALL_OPTIONS = [
  {
    label: 'School',
    value: 'SCHOOL',
  },
  {
    label: 'College',
    value: 'COLLEGE',
  },
  {
    label: 'Group of Institute',
    value: 'GROUP',
  },
];
export const moduleOptions = {
  INDIVIDUAL: {
    label: 'Individual',
    value: 'INDIVIDUAL',
  },
  GROUP: {
    label: 'Group',
    value: 'GROUP',
  },
};

export const INSTITUTEGROUP = 'GROUP';
export const SCHOOL = 'SCHOOL';
export const COLLEGE = 'COLLEGE';
export const SUCCESS = 'success';
export const MANUAL = 'manual';
export const ERROR = 'error';
export const INDETITY = 'Identity';
export const PROFILE_PHOTO = 'ProfilePhoto';

export const INSTITUTE_PREFIX = {
  GROUP: 'ig_',
} as any;

export const ACTION_MODE = ['view', 'edit'];
export const AUDIENCE_TYPE_ARRAY = ['ALL', 'BATCH', 'GROUP', 'INSTITUTE', 'STUDENTS', 'COHORT', 'SUBJECTGROUP'];
export const APPLICANT_TYPE_ENUM = ['DIRECT', 'CAP', 'PROMOTED', 'LOTTERY'];
export enum AUDIENCE_TYPE {
  ALL = 'ALL',
  BATCH = 'BATCH',
  GROUP = 'GROUP',
  INSTITUTE = 'INSTITUTE',
  STUDENTS = 'STUDENTS',
  SUBJECTGROUP = 'SUBJECTGROUP',
  INDIVIDUAL = 'STUDENTS',
  ONE_TO_ONE = 'ONETOONE',
};
export enum AUDIENCEPAGE {
  VIEW = 0,
  BATCH = 1,
  STUDENTS = 2,
  GROUP = 3,
  COHORT = 4,
  SUBJECTGROUP = 5,
};

export const AUDIENCE_SELECTION = {
  BATCH: "Batch",
  GROUP: "Group",
  STUDENTS: "Students",
  INSTITUTE: "Institute",
  SUBJECTGROUP: "Subject Group",
  INDIVIDUAL: "Students"
};

export enum FileCategory {
  News = 'NEWS',
  Notice = 'NOTICE',
  Event = 'EVENT',
  Assignment = 'ASSIGNMENT',
  Education = 'Education',
  Experience = 'Experience',
  Identity = 'Identity',
  Proof = 'Proof',
  Payment = 'Payment',
}

export const ANNOUNCEMENT_TYPE = [
  FileCategory.News,
  FileCategory.Notice,
  FileCategory.Event,
];

export const USER_TYPE = {
  EDBA_ADMIN: 1,
  EMPLOYEE: 2,
  STUDENT: 3,
  APPLICANT: 4,
  EDUCATION_DETAILS: 5
};
export enum UserType {
  EDBA = 1,
  INSTITUTE_EMPLOYEE = 2,
  INSTITUTE_STUDENT = 3,
  APPLICANT = 4,
  EDUCATION_DETAILS = 5
};

export const USER_TYPE_ENUM = ['EDBA', 'INSTITUTE_EMPLOYEE', 'INSTITUTE_STUDENT', 'APPLICANT'];
export const LINK_STATUS = ['UNLINKED', 'LINKED'];
export const BROADCAST_TYPE = {
  reminder: 'ONBOARDING_REMINDER',
  verification: 'VERIFICATION_INVITE',
};

export const AUTOCOMPLETE_MODULE = {
  COURSE: 'course',
  CLASS: 'class',
  STREAM: 'stream',
  UNIVERSITY: 'university',
  INSTITUTE_TYPE: 'type',
  INSTITUTE_GROUP: 'parent',
  SUBJECT: 'subject',
  SUBJECT_GROUP: 'subject_group',
  ASSIGNEE_TYPE: 'assigneeType',
  USER: 'user',
  CLASSES: 'classes',
  COURSES: 'courses',
  CLASSES_STUDENTS: 'classes_students',
  INSTITUTE: 'institute',
  EMPLOYEES: 'employees',
  DIVISION: 'division',
  BATCH: 'batch',
  SUBGROUP: 'subgroup',
  LANGUAGE: 'language',
  KEY_VALUE: 'key_value',
  MODULE: 'module',
  BANK_DETAILS: 'bank_details',
  STUDENT: 'student',
  STUDENTGRP: 'student_grp',
  CONCESSION: 'concession',
  EXAMS: 'exams',
  APPLICANT: 'applicant',
  APPLICATION: 'application',
  ADMISSION: 'admission',
  ADMISSION_CRITERIA: 'admission_criteria',
  DOCUMENTS: 'documents',
  CASTE_CATEGORY: 'caste_category',
  RESERVATION_GROUP: 'reservation_group',
  QUALIFICATIONS: 'qualifications',
  ROUND: 'round',
  LINK: 'link',
  APPLICATION_ID: 'application_id',
  DIRECT_INVITE: 'direct_invite',
  PAYMENT: 'payment',
  STUDENTS: 'students',
  GRADING_SYSTEM: 'grading_system',
  LOTTERY: 'lottery',
  MARKS_STUDENTS: 'marks_students',
  PUBLIC_INVITES: 'public_invites',
  RESULTS_STUDENTS: 'results_students',
  MERCHANTS: 'merchants',
};

export const EVENT_DOCUMENT_TYPE = 'event';
export const ASSIGNEE_LIMIT = 9;
export const MOBILE_ASSIGNEE_LIMIT = 6;
export const FOOTER_MENU = [
  'dashboard',
  'assignments',
  'communication',
  'profile',
  'time-table',
  'attendance',
];
export const EMPLOYEE_TYPES = {
  INSTITUTE_ADMIN: 'ADMIN',
  TEACHING: 'TEACHING',
  NON_TEACHING: 'NON_TEACHING',
  HOD: 'HOD',
};

export const DEVICE_TYPE = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
};

export const FILE_ICONS = {
  pdf: faFilePdf,
  jpg: faFileImage,
  png: faFileImage,
  heic: faFileImage,
  jpeg: faFileImage,
  generic: faFile,
  mp4: faFileVideo,
  csv: faFileCsv,
  excel: faFileSpreadsheet,
  docx: faFileWord,
  doc: faFileWord,
} as any;

export const FILE_ICONS_COLOR = {
  'file-pdf': colors.pdf,
  'file-image': colors.image,
  'file': colors.file,
  'file-video': colors.video,
  'file-csv': colors.csv,
  'file-excel': colors.excel,
  'file-alt': colors.docx
}

export const DURATION_TYPE = {
  DAYS: 'Days',
  MONTH: 'Month',
  YEARS: 'Years',
} as any;


export const ASSIGNMENT_LIMIT = 10;
export const EVENT_LIMIT = 5;
export const NEWS_LIMIT = 10;
export const NOTICE_LIMIT = 10;
export const MESSAGE_LIMIT = 10;
export const ASSIGNMENT_MODAL_PAGE = [
  'ASSIGNMENT_DETAIL',
  'STUDENT_LIST',
  'STUDENT_INFORMATION',
  'YOUR_WORK',
];

export enum DetailStatusEnum {
  NOT_FILLED = "NOT_FILLED",
  NOT_VERIFIED = "NOT_VERIFIED",
  REJECTED = "REJECTED",
  SUBMITTED_FOR_REVIEW = "SUBMITTED_FOR_REVIEW",
  VERIFIED = "VERIFIED"
}

export enum DetailTypeEnum {
  addressDetails = "ADDRESS",
  educationDetails = "EDUCATION",
  experienceDetails = "EXPERIENCE",
  otherDetails = "OTHER",
  parentDetails = "PARENT",
  personalDetails = "PERSONAL"
}

export const SchemaNameUserDocumentCategoryMapper = {
  educationDetails: "Education",
  experienceDetails: "Experience",
  otherDetails: "Other",
  parentDetails: "Parent",
} as any;

export enum AdmissionStatus {
  OPEN = "OPEN",
  CLOSE = "CLOSE"
}
export const NON_TEACHING_EMPLOYEE_TYPE = "NON TEACHING";

export const ApplicationStatusLabel = {
  ADMISSION_COMPLETED: "ADMISSION COMPLETED",
  BLOCKED: "BLOCKED",
  CANCELLED: "CANCELLED",
  INCOMPLETE: "INCOMPLETE",
  PAYMENT_AWAITED: "PAYMENT REQUESTED",
  PAYMENT_COMPLETED: "PAYMENT COMPLETED",
  PAYMENT_POSTPONED: "PAYMENT POSTPONED",
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
  VERIFIED: "VERIFIED",
  APPLICATION_FEE_PENDING: "APPLICATION FEE PENDING",
  AUTO_VERIFIED: "AUTO VERIFIED",
  TRANSFERED: "TRANSFERRED",
  PARTIAL_PAYMENT_COMPLETED: "PARTIAL PAYMENT",
  PRE_ADMISSION_CANCELLATION: "PRE-ADMISSION CANCELLATION",
  POST_ADMISSION_CANCELLATION: "POST-ADMISSION CANCELLATION",
  CANCELLATION_CHARGES_PAID: "CANCELLATION CHARGES PAID",
  ADMISSION_CANCELLATION: "ADMISSION CANCELLATION",
};

export const AdmissionStatusDropdownOptions = Object.keys(ApplicationStatusLabel).map((key) => ({
  label: ApplicationStatusLabel[key as keyof typeof ApplicationStatusLabel],
  value: key,
}));

export enum ApplicationStatus {
  ADMISSION_COMPLETED = "ADMISSION_COMPLETED",
  BLOCKED = "BLOCKED",
  CANCELLED = "CANCELLED",
  INCOMPLETE = "INCOMPLETE",
  PAYMENT_AWAITED = "PAYMENT_AWAITED",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
  PAYMENT_POSTPONED = "PAYMENT_POSTPONED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
  VERIFIED = "VERIFIED",
  APPLICATION_FEE_PENDING = "APPLICATION_FEE_PENDING",
  AUTO_VERIFIED = "AUTO_VERIFIED",
  TRANSFERED = "TRANSFERED",
  PARTIAL_PAYMENT_COMPLETED = "PARTIAL_PAYMENT_COMPLETED",
  PRE_ADMISSION_CANCELLATION = "PRE_ADMISSION_CANCELLATION",
  POST_ADMISSION_CANCELLATION = "POST_ADMISSION_CANCELLATION",
  CANCELLATION_CHARGES_PAID = "CANCELLATION_CHARGES_PAID",
  ADMISSION_CANCELLATION = "ADMISSION_CANCELLATION",
}

export const STUDENT_APPLICATION_OPTIONS = [
  {
    label: "PROMOTED",
    value: "PROMOTED",
  }
];

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFICATION_SENT = "VERIFICATION_SENT"
}

export const statusArray = [DetailStatusEnum.VERIFIED, DetailStatusEnum.REJECTED];

export enum UserDocumentCategory {
  Education,
  Experience,
  Identity,
  Other
};

export const enum PaymentMode {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  DIGITAL = "DIGITAL",
  DRAFT = "DRAFT",
  ONLINE = "DIGITAL",
  RECONCILE = "RECONCILE",
  OFFLINE_NEFT = "OFFLINE_NEFT",
  LOAN = "LOAN",
  DONATION = "DONATION",
};

export const ResultsStatusLabel = {
  ELIGIBLE: "ELIGIBLE",
  NOT_ELIGIBLE: "NOT ELIGIBLE",
  ADMITTED: "ADMITTED",
  PROMOTED: "PROMOTED",
};

export const allowedTypes = [
  'image/bmp',
  'image/jpeg',
  'image/x-png',
  'image/png',
  'image/gif',
  'application/pdf',
  'text/plain',
];

export enum FeePaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING"
};

export const PAYMENT_MODE_LABEL: Record<string, string> = {
  CASH: 'Cash',
  CHEQUE: 'Cheque',
  DRAFT: 'Draft',
  DIGITAL: 'Online',
  RECONCILE: 'Reconcile',
  OFFLINE_NEFT: 'NEFT',
  LOAN: 'Loan',
  DONATION: 'Donation',
} as any;

export enum DefaultUserSelection {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED"
};

export const PaymentStatusLabel: any = {
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
  FAILED: "Failed",
  INITIATED: "Initiated",
  IN_PROCESS: "In Process",
  NODATA: "No Data",
  REFUND_CANCELLED: "Refund Cancelled",
  REFUND_COMPLETED: "Refund Completed",
  REFUND_IN_PROCESS: "Refund in process",
  TRANSFERRED: "Transferred",
  UPDATED: "Updated",
}

export const AdmissionProcessIdLabel: Record<string, string> = {
  CAP: "CAP Application ID",
  NONE: "None",
  UNIVERSITY: "University Application No",
};

export const ADMISSION_PROCESS_ID_ENUM = ['CAP', 'NONE', 'UNIVERSITY'];

export const OrientationLabel = {
  POTRAIT: "Portrait",
  LANDSCAPE: "Landscape",
} as any;

export const PAYMENT_PROOF_TYPE = 'PAYMENT_PROOF';

export const TestTypeLabel = {
  INTERNAL: "Internal",
  EXTERNAL: "External"
} as any;

export const TestMarksTypeLabel = {
  GRADE:"Grading Based",
  MARK:"Marks Based"
} as any;

export enum PAYMENT_REPORT_TYPES {
  PAYMENT_SUMMARY = "PAYMENT_SUMMARY",
  PAYMENT_SUMMARY_DIVISION_WISE = "PAYMENT_SUMMARY_DIVISION_WISE",
};

export const GRADING_TYPE_LABEL: Record<string, string> = {
  CREDIT_BASED_GRADING: "Credit Based Grading System",
  MARKS: "Marking System"
} as any;

export const RegistrationNoPreferenceLabel = {
  BATCH: "Batch",
  COURSE: "Course",
  INSTITUTE: "Institute",
} as any;